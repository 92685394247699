import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  Text,
  Link,
  Center,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { getRoutePath } from "helpers";
import { useMutation } from "@tanstack/react-query";
import { login as loginAPI } from "proto/auth/v1/auth-AuthAPI_connectquery";
import { useAuthContext } from "hooks";

type LoginForm = {
  email: string;
  password: string;
};

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginForm>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const toast = useToast();
  const { login } = useAuthContext();

  const { mutate } = useMutation({
    ...loginAPI.useMutation(),
    onSuccess: () => {
      toast({
        title: "Logged in!",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      login();
    },
    onError: () => {
      toast({
        title: "Invalid Credentials.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const onSubmit = async (data: LoginForm) => {
    mutate({
      username: data.email,
      password: data.password,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
        h="100vh"
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Sign in to your account</Heading>
            {/* <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link color={"blue.400"}>features</Link> ✌️
          </Text> */}
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="email" isInvalid={!!errors?.email}>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  {...register("email", {
                    required: "Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid Email",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors?.email && errors?.email?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={!!errors?.password}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register("password", {
                    required: "Required",
                    minLength: {
                      value: 1,
                      message: "Required",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors?.password && errors?.password?.message}
                </FormErrorMessage>
              </FormControl>
              <Stack spacing={2}>
                <Button isLoading={isSubmitting} type="submit">
                  Sign in
                </Button>

                <Flex justify={"center"} pt={4}>
                  No Account?{" "}
                  <Link
                    px={1}
                    color={"blue.400"}
                    as={RouterLink}
                    to="/register"
                  >
                    Register
                  </Link>
                </Flex>
                <Center>
                  <Link
                    px={1}
                    color={"blue.400"}
                    as={RouterLink}
                    to="/reset-password"
                  >
                    Reset Password
                  </Link>
                </Center>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </form>
  );
}
