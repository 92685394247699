// @generated by protoc-gen-es v1.2.0 with parameter "target=js+dts"
// @generated from file setting/v1/setting_api.proto (package setting.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { VideoQuality } from "../../base/v1/media_pb.js";

/**
 * @generated from enum setting.v1.CameraType
 */
export const CameraType = proto3.makeEnum(
  "setting.v1.CameraType",
  [
    {no: 0, name: "CAMERA_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "CAMERA_TYPE_WEBCAM", localName: "WEBCAM"},
    {no: 2, name: "CAMERA_TYPE_RTSP", localName: "RTSP"},
    {no: 3, name: "CAMERA_TYPE_LFS", localName: "LFS"},
    {no: 4, name: "CAMERA_TYPE_EDGECASTER", localName: "EDGECASTER"},
  ],
);

/**
 * Setting represents general settings for the system to be used
 *
 * @generated from message setting.v1.Setting
 */
export const Setting = proto3.makeMessageType(
  "setting.v1.Setting",
  () => [
    { no: 1, name: "video_export_quality", kind: "enum", T: proto3.getEnumType(VideoQuality) },
    { no: 2, name: "detect_players", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "detect_shuttle", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "show_players", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "show_shuttle", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message setting.v1.GetSettingRequest
 */
export const GetSettingRequest = proto3.makeMessageType(
  "setting.v1.GetSettingRequest",
  [],
);

/**
 * @generated from message setting.v1.GetSettingResponse
 */
export const GetSettingResponse = proto3.makeMessageType(
  "setting.v1.GetSettingResponse",
  () => [
    { no: 1, name: "setting", kind: "message", T: Setting },
  ],
);

/**
 * @generated from message setting.v1.UpdateSettingRequest
 */
export const UpdateSettingRequest = proto3.makeMessageType(
  "setting.v1.UpdateSettingRequest",
  () => [
    { no: 1, name: "setting", kind: "message", T: Setting },
  ],
);

/**
 * @generated from message setting.v1.UpdateSettingResponse
 */
export const UpdateSettingResponse = proto3.makeMessageType(
  "setting.v1.UpdateSettingResponse",
  [],
);

/**
 * @generated from message setting.v1.ListCamerasRequest
 */
export const ListCamerasRequest = proto3.makeMessageType(
  "setting.v1.ListCamerasRequest",
  [],
);

/**
 * @generated from message setting.v1.Camera
 */
export const Camera = proto3.makeMessageType(
  "setting.v1.Camera",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(CameraType) },
    { no: 4, name: "fps", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "frame_height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "frame_width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "lenscorrection", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "secret_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message setting.v1.ListCamerasResponse
 */
export const ListCamerasResponse = proto3.makeMessageType(
  "setting.v1.ListCamerasResponse",
  () => [
    { no: 1, name: "cameras", kind: "message", T: Camera, repeated: true },
  ],
);

/**
 * @generated from message setting.v1.AddCameraRequest
 */
export const AddCameraRequest = proto3.makeMessageType(
  "setting.v1.AddCameraRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(CameraType) },
    { no: 3, name: "fps", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "frame_height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "frame_width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "lenscorrection", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "secret_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message setting.v1.AddCameraResponse
 */
export const AddCameraResponse = proto3.makeMessageType(
  "setting.v1.AddCameraResponse",
  [],
);

/**
 * @generated from message setting.v1.DeleteCameraRequest
 */
export const DeleteCameraRequest = proto3.makeMessageType(
  "setting.v1.DeleteCameraRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message setting.v1.DeleteCameraResponse
 */
export const DeleteCameraResponse = proto3.makeMessageType(
  "setting.v1.DeleteCameraResponse",
  [],
);

/**
 * @generated from message setting.v1.UnLockCameraRequest
 */
export const UnLockCameraRequest = proto3.makeMessageType(
  "setting.v1.UnLockCameraRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message setting.v1.UnLockCameraResponse
 */
export const UnLockCameraResponse = proto3.makeMessageType(
  "setting.v1.UnLockCameraResponse",
  [],
);

