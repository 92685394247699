import { ReactNode } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGlobalStateContext } from "hooks/useGlobalState";
import { AccountDeleteRequested, DisplayError, Loading } from "components";
import { useLogout } from "hooks";
import QRCode from "react-qr-code";
import {
  Avatar,
  Box,
  chakra,
  Flex,
  HStack,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  Menu,
  useColorModeValue,
  useDisclosure,
  Modal,
  Center,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  MenuGroup,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Icon,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import { AiFillVideoCamera, AiOutlineMenu } from "react-icons/ai";
import { MdLiveTv, MdSpaceDashboard } from "react-icons/md";
import { FaChalkboardTeacher, FaServer, FaUserCog } from "react-icons/fa";
import { protoUserName } from "helpers";
import { CgProfile } from "react-icons/cg";
import { HiOutlineLogout } from "react-icons/hi";
import { appName } from "env";
import { Code } from "@bufbuild/connect";

const allLinks = [
  {
    label: "Dashboard",
    icon: <MdSpaceDashboard />,
    iconPlain: MdSpaceDashboard,
    link: "/",
    match: new RegExp(/^[/]$/g),
  },
  {
    label: "Users",
    icon: <FaUserCog />,
    iconPlain: FaUserCog,
    link: "/users",
    match: new RegExp(/[/]user/g),
  },
  {
    label: "Recordings",
    icon: <FaChalkboardTeacher />,
    iconPlain: FaChalkboardTeacher,
    link: "/recordings",
    match: new RegExp(/[/]recording/g),
  },
  {
    label: "Streams",
    icon: <MdLiveTv />,
    iconPlain: MdLiveTv,
    link: "/streams",
    match: new RegExp(/[/]stream/g),
  },
  {
    label: "Cameras",
    icon: <AiFillVideoCamera />,
    iconPlain: AiFillVideoCamera,
    link: "/cameras",
    match: new RegExp(/[/]camera/g),
  },
  {
    label: "Server",
    icon: <FaServer />,
    iconPlain: FaServer,
    link: "/server",
    match: new RegExp(/[/]server/g),
  },
];

export const MainLayout = ({ children }: { children: ReactNode }) => {
  const bg = useColorModeValue("gray.50", "gray.800");
  const sidebar = useDisclosure();
  const { loaded, data, error, isError, isLandscape } = useGlobalStateContext();
  const location = useLocation();
  const onLogout = useLogout();
  const { isOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  if (!loaded) {
    return <Loading />;
  }
  if (isError) {
    if (error?.code === Code.Unavailable) {
      return <AccountDeleteRequested error={error} />;
    }
    return <DisplayError error={error} />;
  }

  const NavItem = (props: any) => {
    const { icon, children, ...rest } = props;
    return (
      <Flex
        align="center"
        px="4"
        pl="4"
        py="3"
        cursor="pointer"
        color="inherit"
        _dark={{
          color: "gray.400",
        }}
        _hover={{
          bg: "gray.100",
          _dark: {
            bg: "gray.900",
          },
          color: "gray.900",
        }}
        role="group"
        fontWeight="semibold"
        transition=".15s ease"
        {...rest}
      >
        {icon && <Icon mx="2" fontSize="lg" as={icon} color="messenger.500" />}
        {children}
      </Flex>
    );
  };

  const SidebarContent = (props: any) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      border
      color="inherit"
      borderRightWidth="1px"
      w="60"
      {...props}
    >
      <Flex px="4" py="5" align="center" justifyContent={""}>
        <Text
          fontSize="2xl"
          ml="2"
          color="messenger.500"
          _dark={{
            color: "white",
          }}
          fontWeight="semibold"
        >
          Reticulo {appName}
        </Text>
        {/* <IconButton
          ml={4}
          size="md"
          icon={<AiOutlineClose />}
          aria-label="Open Menu"
          onClick={isOpen ? onClose : onOpen}
        /> */}
      </Flex>
      <Flex
        direction="column"
        as="nav"
        fontSize="sm"
        color="gray.600"
        aria-label="Main Navigation"
      >
        {allLinks
          .filter((l) => !!data)
          .map((l) => {
            return (
              <Link to={l.link} onClick={sidebar.onClose} key={l.link}>
                <NavItem icon={l.iconPlain}>
                  <Text textColor={"messenger.500"} fontSize="lg">
                    {l.label}
                  </Text>
                </NavItem>
              </Link>
            );
          })}
      </Flex>
    </Box>
  );

  return (
    <Box bg={bg} overflow="hidden" minH="100vh">
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
      <React.Fragment>
        <chakra.header
          display={!location.pathname.includes("/rallies") ? "block" : "none"}
          bg={"messenger.500"}
          w="full"
          px={{
            base: 2,
            sm: 4,
          }}
          roundedBottom={"lg"}
        >
          <Flex
            alignItems="center"
            // justifyContent="space-between"
            mx="auto"
            h={isLandscape ? "7vh" : "7vh"}
          >
            <HStack display="flex" spacing={3} zIndex={500}>
              <Box>
                <IconButton
                  aria-label="Open menu"
                  fontSize="20px"
                  color="white"
                  _dark={{
                    color: "inherit",
                  }}
                  _hover={{
                    bgColor: "",
                  }}
                  variant="filled"
                  icon={<AiOutlineMenu />}
                  onClick={sidebar.onOpen}
                />
              </Box>
              <Box display={{ base: "none", lg: "block" }}>
                <Center fontSize={["2xl", "3xl", "3xl"]} color="white">
                  {appName}
                </Center>
              </Box>
            </HStack>
            <HStack display={{ lg: "none" }}>
              <Text fontSize={["2xl", "3xl", "3xl"]} color="white">
                {appName}
              </Text>
            </HStack>
            <HStack
              w="100%"
              justifyContent="flex-end"
              spacing={1}
              // display={sidebar.isOpen ? "none" : "flex"}
              alignItems="flex-end"
            >
              <SimpleGrid columns={2}>
                <GridItem colSpan={1}>
                  <Center h="full">
                    <Menu>
                      <MenuButton
                        border="solid"
                        borderColor="messenger.600"
                        as={IconButton}
                        rounded={"full"}
                        variant={"link"}
                        cursor={"pointer"}
                        minW={0}
                        defaultValue={-1}
                      >
                        <Avatar bg="messenger.900" size="xs" />
                      </MenuButton>
                      <MenuList>
                        <MenuGroup
                          title={`Welcome ${protoUserName(data?.user)}!`}
                        >
                          <MenuItem
                            onClick={() => navigate("/profile")}
                            icon={<CgProfile />}
                          >
                            My Profile
                          </MenuItem>
                          <MenuItem
                            onClick={onLogout}
                            icon={<HiOutlineLogout />}
                          >
                            Logout
                          </MenuItem>
                        </MenuGroup>
                      </MenuList>
                    </Menu>
                  </Center>
                </GridItem>
              </SimpleGrid>
            </HStack>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent shadow={"lg"} maxW="80vw">
                <ModalHeader>Your QR Code</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Center pb={12}>
                    <QRCode value={JSON.stringify(data?.user ?? "{}")} />
                  </Center>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </chakra.header>
      </React.Fragment>
      {children}
    </Box>
  );
};
