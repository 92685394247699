// @generated by protoc-gen-connect-query v0.2.0 with parameter "target=ts"
// @generated from file auth/v1/auth.proto (package auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { createQueryService } from "@bufbuild/connect-query";
import { MethodKind } from "@bufbuild/protobuf";
import { ChangePasswordRequest, ChangePasswordResponse, LoginRequest, LoginResponse, LogoutRequest, LogoutResponse, RegisterRequest, RegisterResponse } from "./auth_pb.js";

export const typeName = "auth.v1.AuthAPI";

/**
 * Login allows users to login
 *
 * @generated from rpc auth.v1.AuthAPI.Login
 */
export const login = createQueryService({
  service: {
    methods: {
      login: {
        name: "Login",
        kind: MethodKind.Unary,
        I: LoginRequest,
        O: LoginResponse,
      },
    },
    typeName: "auth.v1.AuthAPI",
  },
}).login;

/**
 * Login allows users to register
 *
 * @generated from rpc auth.v1.AuthAPI.Register
 */
export const register = createQueryService({
  service: {
    methods: {
      register: {
        name: "Register",
        kind: MethodKind.Unary,
        I: RegisterRequest,
        O: RegisterResponse,
      },
    },
    typeName: "auth.v1.AuthAPI",
  },
}).register;

/**
 * Login allows users to change their password
 *
 * @generated from rpc auth.v1.AuthAPI.ChangePassword
 */
export const changePassword = createQueryService({
  service: {
    methods: {
      changePassword: {
        name: "ChangePassword",
        kind: MethodKind.Unary,
        I: ChangePasswordRequest,
        O: ChangePasswordResponse,
      },
    },
    typeName: "auth.v1.AuthAPI",
  },
}).changePassword;

/**
 * Logs out a user from session
 *
 * @generated from rpc auth.v1.AuthAPI.Logout
 */
export const logout = createQueryService({
  service: {
    methods: {
      logout: {
        name: "Logout",
        kind: MethodKind.Unary,
        I: LogoutRequest,
        O: LogoutResponse,
      },
    },
    typeName: "auth.v1.AuthAPI",
  },
}).logout;
