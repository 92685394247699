import { BrowserRouter } from "react-router-dom";
import { Screens } from "Screens";
import { isPublic } from "config";
import { Home } from "Home";
import { AuthProvider } from "hooks";

export const Main = () => {
  if (isPublic) {
    return (
      <BrowserRouter>
        <Home />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <Screens />
      </AuthProvider>
    </BrowserRouter>
  );
};
