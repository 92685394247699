import { useEffect, useState } from "react";
import constate from "constate";
import { useQuery } from "@tanstack/react-query";
import { getProfile } from "proto/user/v1/user_api-UserAPI_connectquery";
import { useWindowSize } from "./useWindowSize";

const useAggProfile = () => {
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery({
    ...getProfile.useQuery({}, {}),
    keepPreviousData: true,
  });

  return {
    isLoading: isLoading || isFetching,
    isError,
    error,
    data,
    refetch,
  };
};

const useGlobalState = () => {
  const [loaded, setLoaded] = useState(false);
  const { data, error, isError, isLoading, refetch } = useAggProfile();
  const windowSize = useWindowSize();
  const [isLandscape, setIsLandscape] = useState(false);

  // we will only show loading for the time we fetch data
  useEffect(() => {
    if (loaded || isLoading) return;
    setLoaded(true);
  }, [loaded, isLoading]);

  useEffect(() => {
    if (windowSize.height > windowSize.width) setIsLandscape(false);
    else if (windowSize.height < windowSize.width) setIsLandscape(true);
  }, [windowSize]);

  return {
    data,
    error,
    isError,
    loaded,
    isFetching: isLoading,
    isLandscape,
    setIsLandscape,
    refetch,
  };
};

const [GlobalStateProvider, useGlobalStateContext] = constate(useGlobalState);

export { GlobalStateProvider, useGlobalStateContext };
