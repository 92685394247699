import { chakra, Container } from "@chakra-ui/react";
import { Loading } from "components";
import { StateProvider } from "hooks/provider";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { MainLayout } from "./layout";

const LoadingFallback = () => {
  return (
    <Container>
      <Loading text="" />
    </Container>
  );
};

const MainDashboardPage = React.lazy(
  () => import("./dashboard/pages/MainDashboardPage")
);

const ProfilePage = React.lazy(() => import("./profile/pages/ProfilePage"));

const RecordingsPage = React.lazy(
  () => import("./recording/pages/RecordingsPage")
);
const StreamsPage = React.lazy(() => import("./stream/pages/StreamsPage"));

const UsersPage = React.lazy(() => import("./user/pages/UsersPage"));
const CreateUserPage = React.lazy(() => import("./user/pages/CreateUserPage"));

const CamerasPage = React.lazy(() => import("./camera/pages/CamerasPage"));
const CreateCameraPage = React.lazy(
  () => import("./camera/pages/CreateCameraPage")
);

const ServerPage = React.lazy(() => import("./server/pages/ServerPage"));

export const Home = () => {
  return (
    <StateProvider>
      <MainLayout>
        <chakra.div>
          <Routes>
            <Route
              path="/recordings"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <RecordingsPage />
                </React.Suspense>
              }
            />

            <Route
              path="/profile"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ProfilePage />
                </React.Suspense>
              }
            />

            <Route
              path="/streams"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <StreamsPage />
                </React.Suspense>
              }
            />

            <Route
              path="/users"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <UsersPage />
                </React.Suspense>
              }
            />
            <Route
              path="/users/new"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <CreateUserPage />
                </React.Suspense>
              }
            />

            <Route
              path="/cameras"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <CamerasPage />
                </React.Suspense>
              }
            />
            <Route
              path="/cameras/new"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <CreateCameraPage />
                </React.Suspense>
              }
            />

            <Route
              path="/server"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ServerPage />
                </React.Suspense>
              }
            />

            <Route
              path="/"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <MainDashboardPage />
                </React.Suspense>
              }
            />
          </Routes>
        </chakra.div>
      </MainLayout>
    </StateProvider>
  );
};
