import { Container } from "@chakra-ui/react";
import { Home } from "Home";
import Login from "Login";
import { Loading } from "components";
import { useAuthContext } from "hooks";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const LoadingFallback = () => {
  return (
    <Container>
      <Loading text="" />
    </Container>
  );
};

export const Screens = () => {
  const { isAuthenticated, loading } = useAuthContext();
  if (loading) {
    return <Loading text="Logging you in" size={180} />;
  }

  return isAuthenticated ? (
    <Home />
  ) : (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<LoadingFallback />}>
            <Login />
          </React.Suspense>
        }
      />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
