// @generated by protoc-gen-es v1.2.0 with parameter "target=js+dts"
// @generated from file recording/v1/recording_api.proto (package recording.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { VideoQuality } from "../../base/v1/media_pb.js";
import { SortDirection } from "../../base/v1/sort_pb.js";
import { Box, Court, Location } from "../../base/v1/location_pb.js";

/**
 * @generated from enum recording.v1.Player
 */
export const Player = proto3.makeEnum(
  "recording.v1.Player",
  [
    {no: 0, name: "PLAYER_INVALID", localName: "INVALID"},
    {no: 1, name: "PLAYER_P1", localName: "P1"},
    {no: 2, name: "PLAYER_P2", localName: "P2"},
  ],
);

/**
 * @generated from enum recording.v1.ShotType
 */
export const ShotType = proto3.makeEnum(
  "recording.v1.ShotType",
  [
    {no: 0, name: "SHOT_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "SHOT_TYPE_TOSS", localName: "TOSS"},
    {no: 2, name: "SHOT_TYPE_DROP", localName: "DROP"},
    {no: 3, name: "SHOT_TYPE_SMASH", localName: "SMASH"},
    {no: 4, name: "SHOT_TYPE_LIFT", localName: "LIFT"},
    {no: 5, name: "SHOT_TYPE_BLOCK", localName: "BLOCK"},
    {no: 6, name: "SHOT_TYPE_DRIVE", localName: "DRIVE"},
    {no: 7, name: "SHOT_TYPE_PUSH", localName: "PUSH"},
    {no: 8, name: "SHOT_TYPE_WIN", localName: "WIN"},
    {no: 9, name: "SHOT_TYPE_ERROR", localName: "ERROR"},
  ],
);

/**
 * @generated from enum recording.v1.ShotDirection
 */
export const ShotDirection = proto3.makeEnum(
  "recording.v1.ShotDirection",
  [
    {no: 0, name: "SHOT_DIRECTION_INVALID", localName: "INVALID"},
    {no: 1, name: "SHOT_DIRECTION_STRAIGHT", localName: "STRAIGHT"},
    {no: 2, name: "SHOT_DIRECTION_CROSS", localName: "CROSS"},
    {no: 3, name: "SHOT_DIRECTION_AGNOSTIC", localName: "AGNOSTIC"},
  ],
);

/**
 * RecordingState indicates the current state of the recording
 *
 * @generated from enum recording.v1.RecordingState
 */
export const RecordingState = proto3.makeEnum(
  "recording.v1.RecordingState",
  [
    {no: 0, name: "RECORDING_STATE_INVALID", localName: "INVALID"},
    {no: 1, name: "RECORDING_STATE_READY", localName: "READY"},
    {no: 2, name: "RECORDING_STATE_DETECTING", localName: "DETECTING"},
    {no: 3, name: "RECORDING_STATE_PRE_PROCESSING", localName: "PRE_PROCESSING"},
    {no: 4, name: "RECORDING_STATE_PROCESSING", localName: "PROCESSING"},
    {no: 5, name: "RECORDING_STATE_PROCESSED", localName: "PROCESSED"},
    {no: 6, name: "RECORDING_STATE_RECORDING", localName: "RECORDING"},
  ],
);

/**
 * @generated from enum recording.v1.MatchType
 */
export const MatchType = proto3.makeEnum(
  "recording.v1.MatchType",
  [
    {no: 0, name: "MATCH_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "MATCH_TYPE_PRACTICE", localName: "PRACTICE"},
    {no: 2, name: "MATCH_TYPE_QUARTER_FINAL", localName: "QUARTER_FINAL"},
    {no: 3, name: "MATCH_TYPE_SEMI_FINAL", localName: "SEMI_FINAL"},
    {no: 4, name: "MATCH_TYPE_FINAL", localName: "FINAL"},
    {no: 5, name: "MATCH_TYPE_PRIVATE", localName: "PRIVATE"},
  ],
);

/**
 * PlayerPositionType defines various positions of player
 *
 * @generated from enum recording.v1.PlayerPositionType
 */
export const PlayerPositionType = proto3.makeEnum(
  "recording.v1.PlayerPositionType",
  [
    {no: 0, name: "PLAYER_POSITION_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "PLAYER_POSITION_TYPE_BACKHAND", localName: "BACKHAND"},
    {no: 2, name: "PLAYER_POSITION_TYPE_BACKHAND_FRONT", localName: "BACKHAND_FRONT"},
    {no: 3, name: "PLAYER_POSITION_TYPE_CENTER", localName: "CENTER"},
    {no: 4, name: "PLAYER_POSITION_TYPE_DEFENSE", localName: "DEFENSE"},
    {no: 5, name: "PLAYER_POSITION_TYPE_FOREHAND_BACK", localName: "FOREHAND_BACK"},
    {no: 6, name: "PLAYER_POSITION_TYPE_FOREHAND_FRONT", localName: "FOREHAND_FRONT"},
    {no: 7, name: "PLAYER_POSITION_TYPE_OVERHAND", localName: "OVERHAND"},
    {no: 8, name: "PLAYER_POSITION_TYPE_SERVE", localName: "SERVE"},
    {no: 9, name: "PLAYER_POSITION_TYPE_NO_HIT", localName: "NO_HIT"},
    {no: 10, name: "PLAYER_POSITION_TYPE_AFTER_HIT", localName: "AFTER_HIT"},
    {no: 11, name: "PLAYER_POSITION_TYPE_NO_PLAYER", localName: "NO_PLAYER"},
    {no: 12, name: "PLAYER_POSITION_TYPE_RELAXED", localName: "RELAXED"},
    {no: 13, name: "PLAYER_POSITION_TYPE_HIT", localName: "HIT"},
  ],
);

/**
 * @generated from enum recording.v1.PracticeType
 */
export const PracticeType = proto3.makeEnum(
  "recording.v1.PracticeType",
  [
    {no: 0, name: "PRACTICE_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "PRACTICE_TYPE_SHADOW_DRILL", localName: "SHADOW_DRILL"},
  ],
);

/**
 * @generated from enum recording.v1.ExportState
 */
export const ExportState = proto3.makeEnum(
  "recording.v1.ExportState",
  [
    {no: 0, name: "EXPORT_STATE_INVALID", localName: "INVALID"},
    {no: 1, name: "EXPORT_STATE_STARTED", localName: "STARTED"},
    {no: 2, name: "EXPORT_STATE_COMPLETED", localName: "COMPLETED"},
  ],
);

/**
 * @generated from enum recording.v1.CommandType
 */
export const CommandType = proto3.makeEnum(
  "recording.v1.CommandType",
  [
    {no: 0, name: "COMMAND_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "COMMAND_TYPE_START_GAME", localName: "START_GAME"},
    {no: 2, name: "COMMAND_TYPE_END_GAME", localName: "END_GAME"},
    {no: 3, name: "COMMAND_TYPE_END_G1", localName: "END_G1"},
    {no: 4, name: "COMMAND_TYPE_START_G2", localName: "START_G2"},
    {no: 5, name: "COMMAND_TYPE_END_MATCH", localName: "END_MATCH"},
  ],
);

/**
 * @generated from message recording.v1.Match
 */
export const Match = proto3.makeMessageType(
  "recording.v1.Match",
  () => [
    { no: 1, name: "no_of_games", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "p1_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "p2_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "games", kind: "message", T: Game, repeated: true },
    { no: 5, name: "score", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "no_of_rallies", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "avg_length", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message recording.v1.Game
 */
export const Game = proto3.makeMessageType(
  "recording.v1.Game",
  () => [
    { no: 1, name: "no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "start_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "end_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "root_start_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "root_end_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "is_player_pos_inverted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "no_of_rallies", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "p1_game_statistics", kind: "message", T: Statistics },
    { no: 9, name: "p2_game_statistics", kind: "message", T: Statistics },
    { no: 10, name: "p1_game_report", kind: "message", T: Report },
    { no: 11, name: "p2_game_report", kind: "message", T: Report },
    { no: 12, name: "rallies", kind: "message", T: Rally, repeated: true },
    { no: 13, name: "p1_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "p2_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "g1", kind: "message", T: Game },
    { no: 16, name: "g2", kind: "message", T: Game },
    { no: 17, name: "middle_point", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.Rally
 */
export const Rally = proto3.makeMessageType(
  "recording.v1.Rally",
  () => [
    { no: 1, name: "no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "start_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "end_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "root_start_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "root_end_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "p1_rally_statistics", kind: "message", T: Statistics },
    { no: 7, name: "p2_rally_statistics", kind: "message", T: Statistics },
    { no: 8, name: "frames", kind: "message", T: Frame, repeated: true },
    { no: 9, name: "started_by", kind: "enum", T: proto3.getEnumType(Player) },
    { no: 10, name: "won_by", kind: "enum", T: proto3.getEnumType(Player) },
    { no: 11, name: "no_of_shots", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "p1_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "p2_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "shot_patterns", kind: "message", T: ShotPatternData, repeated: true },
    { no: 15, name: "shots", kind: "message", T: ShotData, repeated: true },
    { no: 16, name: "hits", kind: "message", T: HitData, repeated: true },
  ],
);

/**
 * RecordingInfo represents the recording info
 *
 * @generated from message recording.v1.RecordingInfo
 */
export const RecordingInfo = proto3.makeMessageType(
  "recording.v1.RecordingInfo",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fps", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "frames", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "frames_processed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "frame_height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "frame_width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "video_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "thumbnail_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "input_video_quality", kind: "enum", T: proto3.getEnumType(VideoQuality) },
    { no: 11, name: "state", kind: "enum", T: proto3.getEnumType(RecordingState) },
    { no: 12, name: "file_size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "created_at", kind: "message", T: Timestamp },
    { no: 14, name: "updated_at", kind: "message", T: Timestamp },
    { no: 15, name: "detection_started_at", kind: "message", T: Timestamp },
    { no: 16, name: "detection_ended_at", kind: "message", T: Timestamp },
    { no: 17, name: "pre_processing_started_at", kind: "message", T: Timestamp },
    { no: 18, name: "pre_processing_ended_at", kind: "message", T: Timestamp },
    { no: 19, name: "processing_started_at", kind: "message", T: Timestamp },
    { no: 20, name: "processing_ended_at", kind: "message", T: Timestamp },
    { no: 21, name: "reprocessed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "match", kind: "message", T: Match },
    { no: 23, name: "empty_court_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "match_type", kind: "enum", T: proto3.getEnumType(MatchType) },
    { no: 25, name: "has_report", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "score", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "original_frames", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 30, name: "is_live", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 31, name: "live_games", kind: "message", T: Game, repeated: true },
    { no: 32, name: "has_ended", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 33, name: "tournament_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "tournament_stage_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 35, name: "setting_match", kind: "message", T: Match },
  ],
);

/**
 * @generated from message recording.v1.ListRecordingsRequest
 */
export const ListRecordingsRequest = proto3.makeMessageType(
  "recording.v1.ListRecordingsRequest",
  () => [
    { no: 1, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "tournament_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.ListRecordingsResponse
 */
export const ListRecordingsResponse = proto3.makeMessageType(
  "recording.v1.ListRecordingsResponse",
  () => [
    { no: 1, name: "recordings", kind: "message", T: RecordingInfo, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.Setting
 */
export const Setting = proto3.makeMessageType(
  "recording.v1.Setting",
  () => [
    { no: 1, name: "detect_players", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "detect_shuttle", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "velocity_graph_interval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "distance_coverage_graph_interval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "movement_pattern_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "movement_pattern_interval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "performance_graph_interval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "match", kind: "message", T: Match },
  ],
);

/**
 * @generated from message recording.v1.Recording
 */
export const Recording = proto3.makeMessageType(
  "recording.v1.Recording",
  () => [
    { no: 1, name: "info", kind: "message", T: RecordingInfo },
    { no: 2, name: "annotations", kind: "message", T: Annotation, repeated: true },
    { no: 3, name: "exports", kind: "message", T: Export, repeated: true },
    { no: 4, name: "setting", kind: "message", T: Setting },
    { no: 6, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "p1_match_statistics", kind: "message", T: Statistics },
    { no: 8, name: "p2_match_statistics", kind: "message", T: Statistics },
    { no: 9, name: "p1_match_report", kind: "message", T: Report },
    { no: 10, name: "p2_match_report", kind: "message", T: Report },
    { no: 11, name: "p1_grids", kind: "message", T: Box, repeated: true },
    { no: 12, name: "p2_grids", kind: "message", T: Box, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.GetRecordingRequest
 */
export const GetRecordingRequest = proto3.makeMessageType(
  "recording.v1.GetRecordingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sort_direction_annotations", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 3, name: "sort_direction_exports", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ],
);

/**
 * @generated from message recording.v1.GetRecordingResponse
 */
export const GetRecordingResponse = proto3.makeMessageType(
  "recording.v1.GetRecordingResponse",
  () => [
    { no: 1, name: "recording", kind: "message", T: Recording },
  ],
);

/**
 * @generated from message recording.v1.DeleteRecordingRequest
 */
export const DeleteRecordingRequest = proto3.makeMessageType(
  "recording.v1.DeleteRecordingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "delete_files", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message recording.v1.DeleteRecordingResponse
 */
export const DeleteRecordingResponse = proto3.makeMessageType(
  "recording.v1.DeleteRecordingResponse",
  [],
);

/**
 * @generated from message recording.v1.ListRecordingFramesRequest
 */
export const ListRecordingFramesRequest = proto3.makeMessageType(
  "recording.v1.ListRecordingFramesRequest",
  () => [
    { no: 1, name: "recording_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ],
);

/**
 * @generated from message recording.v1.PlayerDetection
 */
export const PlayerDetection = proto3.makeMessageType(
  "recording.v1.PlayerDetection",
  () => [
    { no: 1, name: "position", kind: "message", T: Box },
    { no: 2, name: "relative_position", kind: "message", T: Box },
    { no: 3, name: "position_type", kind: "enum", T: proto3.getEnumType(PlayerPositionType) },
  ],
);

/**
 * @generated from message recording.v1.DetectFrame
 */
export const DetectFrame = proto3.makeMessageType(
  "recording.v1.DetectFrame",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "p1", kind: "message", T: PlayerDetection },
    { no: 3, name: "p2", kind: "message", T: PlayerDetection },
    { no: 4, name: "s1", kind: "message", T: Location },
  ],
);

/**
 * @generated from message recording.v1.Frame
 */
export const Frame = proto3.makeMessageType(
  "recording.v1.Frame",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "p1", kind: "message", T: Box },
    { no: 3, name: "p2", kind: "message", T: Box },
    { no: 4, name: "p1_relative", kind: "message", T: Box },
    { no: 5, name: "p2_relative", kind: "message", T: Box },
    { no: 6, name: "p1_court", kind: "message", T: Box },
    { no: 7, name: "p2_court", kind: "message", T: Box },
    { no: 8, name: "p1_grid_pos", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "p2_grid_pos", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "p1_hit", kind: "enum", T: proto3.getEnumType(PlayerPositionType) },
    { no: 11, name: "p2_hit", kind: "enum", T: proto3.getEnumType(PlayerPositionType) },
    { no: 12, name: "s1", kind: "message", T: Location },
    { no: 13, name: "root_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.ListRecordingFramesResponse
 */
export const ListRecordingFramesResponse = proto3.makeMessageType(
  "recording.v1.ListRecordingFramesResponse",
  () => [
    { no: 1, name: "frames", kind: "message", T: Frame, repeated: true },
    { no: 2, name: "games", kind: "message", T: Game, repeated: true },
    { no: 3, name: "rallies", kind: "message", T: Rally, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingNotesRequest
 */
export const UpdateRecordingNotesRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingNotesRequest",
  () => [
    { no: 1, name: "recording_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingNotesResponse
 */
export const UpdateRecordingNotesResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingNotesResponse",
  [],
);

/**
 * @generated from message recording.v1.CreateRecordingRequest
 */
export const CreateRecordingRequest = proto3.makeMessageType(
  "recording.v1.CreateRecordingRequest",
  () => [
    { no: 1, name: "video_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.CreateRecordingResponse
 */
export const CreateRecordingResponse = proto3.makeMessageType(
  "recording.v1.CreateRecordingResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.RegenerateRecordingThumbnailRequest
 */
export const RegenerateRecordingThumbnailRequest = proto3.makeMessageType(
  "recording.v1.RegenerateRecordingThumbnailRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.RegenerateRecordingThumbnailResponse
 */
export const RegenerateRecordingThumbnailResponse = proto3.makeMessageType(
  "recording.v1.RegenerateRecordingThumbnailResponse",
  [],
);

/**
 * @generated from message recording.v1.ProcessRecordingRequest
 */
export const ProcessRecordingRequest = proto3.makeMessageType(
  "recording.v1.ProcessRecordingRequest",
  () => [
    { no: 1, name: "recording_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "setting", kind: "message", T: Setting },
    { no: 3, name: "show_players", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "show_shuttle", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "court", kind: "message", T: Court },
    { no: 6, name: "use_detection_hits_file", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "use_detection_file", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "generate_shot_videos", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message recording.v1.ProcessRecordingResponse
 */
export const ProcessRecordingResponse = proto3.makeMessageType(
  "recording.v1.ProcessRecordingResponse",
  [],
);

/**
 * @generated from message recording.v1.ReProcessRecordingRequest
 */
export const ReProcessRecordingRequest = proto3.makeMessageType(
  "recording.v1.ReProcessRecordingRequest",
  () => [
    { no: 1, name: "recording_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "redo_detection", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "redo_pre_processing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "redo_processing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "setting", kind: "message", T: Setting },
    { no: 6, name: "force_reprocess", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "shrink_video", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "update_hits", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "update_hits_content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 10, name: "regenerate_shot_videos", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message recording.v1.ReProcessRecordingResponse
 */
export const ReProcessRecordingResponse = proto3.makeMessageType(
  "recording.v1.ReProcessRecordingResponse",
  [],
);

/**
 * @generated from message recording.v1.CreateCourtVerificationFrameRequest
 */
export const CreateCourtVerificationFrameRequest = proto3.makeMessageType(
  "recording.v1.CreateCourtVerificationFrameRequest",
  () => [
    { no: 1, name: "recording_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "court", kind: "message", T: Court },
  ],
);

/**
 * @generated from message recording.v1.CreateCourtVerificationFrameResponse
 */
export const CreateCourtVerificationFrameResponse = proto3.makeMessageType(
  "recording.v1.CreateCourtVerificationFrameResponse",
  () => [
    { no: 1, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.DeleteAnnotationRequest
 */
export const DeleteAnnotationRequest = proto3.makeMessageType(
  "recording.v1.DeleteAnnotationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "delete_files", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message recording.v1.DeleteAnnotationResponse
 */
export const DeleteAnnotationResponse = proto3.makeMessageType(
  "recording.v1.DeleteAnnotationResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateRecordingNameRequest
 */
export const UpdateRecordingNameRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingNameRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingNameResponse
 */
export const UpdateRecordingNameResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingNameResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateRecordingMatchTypeRequest
 */
export const UpdateRecordingMatchTypeRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingMatchTypeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "match_type", kind: "enum", T: proto3.getEnumType(MatchType) },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingMatchTypeResponse
 */
export const UpdateRecordingMatchTypeResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingMatchTypeResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateRecordingPasswordRequest
 */
export const UpdateRecordingPasswordRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingPasswordRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingPasswordResponse
 */
export const UpdateRecordingPasswordResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingPasswordResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateRecordingScoreRequest
 */
export const UpdateRecordingScoreRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingScoreRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "score", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingScoreResponse
 */
export const UpdateRecordingScoreResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingScoreResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateRecordingTagRequest
 */
export const UpdateRecordingTagRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingTagRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingTagResponse
 */
export const UpdateRecordingTagResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingTagResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateRecordingSettingsRequest
 */
export const UpdateRecordingSettingsRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingSettingsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "p1_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "p2_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "score", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingSettingsResponse
 */
export const UpdateRecordingSettingsResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingSettingsResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateRecordingTournamentRequest
 */
export const UpdateRecordingTournamentRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingTournamentRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tournament_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingTournamentResponse
 */
export const UpdateRecordingTournamentResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingTournamentResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateRecordingTournamentStageKeyRequest
 */
export const UpdateRecordingTournamentStageKeyRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingTournamentStageKeyRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stage_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingTournamentStageKeyResponse
 */
export const UpdateRecordingTournamentStageKeyResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingTournamentStageKeyResponse",
  [],
);

/**
 * @generated from message recording.v1.GenerateRecordingHitDatasetRequest
 */
export const GenerateRecordingHitDatasetRequest = proto3.makeMessageType(
  "recording.v1.GenerateRecordingHitDatasetRequest",
  () => [
    { no: 1, name: "recording_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "samples", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.GenerateRecordingHitDatasetResponse
 */
export const GenerateRecordingHitDatasetResponse = proto3.makeMessageType(
  "recording.v1.GenerateRecordingHitDatasetResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateRecordingEmptyCourtRequest
 */
export const UpdateRecordingEmptyCourtRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingEmptyCourtRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingEmptyCourtResponse
 */
export const UpdateRecordingEmptyCourtResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingEmptyCourtResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateRecordingReportRequest
 */
export const UpdateRecordingReportRequest = proto3.makeMessageType(
  "recording.v1.UpdateRecordingReportRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateRecordingReportResponse
 */
export const UpdateRecordingReportResponse = proto3.makeMessageType(
  "recording.v1.UpdateRecordingReportResponse",
  [],
);

/**
 * @generated from message recording.v1.GeneralStatistics
 */
export const GeneralStatistics = proto3.makeMessageType(
  "recording.v1.GeneralStatistics",
  () => [
    { no: 1, name: "distance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "velocity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "velocity_avg", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "velocity_max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "performance_index", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "forward_movements", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "backward_movements", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 9, name: "p1_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "p2_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.HitStatistics
 */
export const HitStatistics = proto3.makeMessageType(
  "recording.v1.HitStatistics",
  () => [
    { no: 1, name: "hit_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "front_upward_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "front_downward_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "back_upward_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "back_downward_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "cross_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "straight_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "neutral_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.Statistics
 */
export const Statistics = proto3.makeMessageType(
  "recording.v1.Statistics",
  () => [
    { no: 1, name: "general_statistics", kind: "message", T: GeneralStatistics },
    { no: 2, name: "hit_statistics", kind: "message", T: HitStatistics },
  ],
);

/**
 * @generated from message recording.v1.HeatmapDataPoint
 */
export const HeatmapDataPoint = proto3.makeMessageType(
  "recording.v1.HeatmapDataPoint",
  () => [
    { no: 1, name: "x", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "y", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 32, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message recording.v1.HeatmapData
 */
export const HeatmapData = proto3.makeMessageType(
  "recording.v1.HeatmapData",
  () => [
    { no: 1, name: "min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "max", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "points", kind: "message", T: HeatmapDataPoint, repeated: true },
    { no: 4, name: "img_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message recording.v1.GraphData
 */
export const GraphData = proto3.makeMessageType(
  "recording.v1.GraphData",
  () => [
    { no: 1, name: "x_points", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
    { no: 2, name: "y_points", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.DistributionData
 */
export const DistributionData = proto3.makeMessageType(
  "recording.v1.DistributionData",
  () => [
    { no: 1, name: "x_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "y_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "points", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.GridPatternDataItemWindow
 */
export const GridPatternDataItemWindow = proto3.makeMessageType(
  "recording.v1.GridPatternDataItemWindow",
  () => [
    { no: 1, name: "start_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "end_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.GridPatternDataItem
 */
export const GridPatternDataItem = proto3.makeMessageType(
  "recording.v1.GridPatternDataItem",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "grids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 3, name: "percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "patterns", kind: "message", T: GridPatternDataItemWindow, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.GridPatternData
 */
export const GridPatternData = proto3.makeMessageType(
  "recording.v1.GridPatternData",
  () => [
    { no: 1, name: "items", kind: "message", T: GridPatternDataItem, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.Trajectory
 */
export const Trajectory = proto3.makeMessageType(
  "recording.v1.Trajectory",
  () => [
    { no: 1, name: "locations", kind: "message", T: Location, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.HitTrajectory
 */
export const HitTrajectory = proto3.makeMessageType(
  "recording.v1.HitTrajectory",
  () => [
    { no: 1, name: "from_grid", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "to_grid", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "trajectory", kind: "message", T: Trajectory },
  ],
);

/**
 * @generated from message recording.v1.ShotPatternData
 */
export const ShotPatternData = proto3.makeMessageType(
  "recording.v1.ShotPatternData",
  () => [
    { no: 1, name: "start_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "end_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(ShotType) },
    { no: 4, name: "direction", kind: "enum", T: proto3.getEnumType(ShotDirection) },
    { no: 5, name: "player", kind: "enum", T: proto3.getEnumType(Player) },
  ],
);

/**
 * @generated from message recording.v1.ShotData
 */
export const ShotData = proto3.makeMessageType(
  "recording.v1.ShotData",
  () => [
    { no: 1, name: "frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(ShotType) },
    { no: 3, name: "direction", kind: "enum", T: proto3.getEnumType(ShotDirection) },
    { no: 4, name: "player", kind: "enum", T: proto3.getEnumType(Player) },
    { no: 5, name: "grid", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.HitData
 */
export const HitData = proto3.makeMessageType(
  "recording.v1.HitData",
  () => [
    { no: 1, name: "frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(PlayerPositionType) },
    { no: 3, name: "player", kind: "enum", T: proto3.getEnumType(Player) },
    { no: 4, name: "grid", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.ShotPatternByType
 */
export const ShotPatternByType = proto3.makeMessageType(
  "recording.v1.ShotPatternByType",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(ShotType) },
    { no: 2, name: "patterns", kind: "message", T: ShotPatternData, repeated: true },
    { no: 3, name: "video_file", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.ShotPatternByDirection
 */
export const ShotPatternByDirection = proto3.makeMessageType(
  "recording.v1.ShotPatternByDirection",
  () => [
    { no: 1, name: "direction", kind: "enum", T: proto3.getEnumType(ShotDirection) },
    { no: 2, name: "patterns", kind: "message", T: ShotPatternData, repeated: true },
    { no: 3, name: "video_file", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.Report
 */
export const Report = proto3.makeMessageType(
  "recording.v1.Report",
  () => [
    { no: 1, name: "movement_density_heatmap", kind: "message", T: HeatmapData },
    { no: 2, name: "movement_density_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "velocity_graph", kind: "message", T: GraphData },
    { no: 4, name: "distance_coverage_graph", kind: "message", T: GraphData },
    { no: 5, name: "shot_distribution", kind: "message", T: DistributionData },
    { no: 6, name: "hit_common_movement_pattern", kind: "message", T: GridPatternData },
    { no: 7, name: "performance_graph", kind: "message", T: GraphData },
    { no: 8, name: "hit_trajectories", kind: "message", T: HitTrajectory, repeated: true },
    { no: 9, name: "shot_patterns_by_type", kind: "message", T: ShotPatternByType, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.MatchReport
 */
export const MatchReport = proto3.makeMessageType(
  "recording.v1.MatchReport",
  () => [
    { no: 1, name: "movement_density_heatmap", kind: "message", T: HeatmapData },
    { no: 2, name: "movement_density_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "velocity_graph", kind: "message", T: GraphData },
    { no: 4, name: "distance_coverage_graph", kind: "message", T: GraphData },
    { no: 5, name: "shot_distribution", kind: "message", T: DistributionData },
    { no: 6, name: "hit_common_movement_pattern", kind: "message", T: GridPatternData },
    { no: 7, name: "performance_graph", kind: "message", T: GraphData },
    { no: 8, name: "hit_trajectories", kind: "message", T: HitTrajectory, repeated: true },
    { no: 9, name: "shot_patterns_by_type", kind: "message", T: ShotPatternByType, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.GameReport
 */
export const GameReport = proto3.makeMessageType(
  "recording.v1.GameReport",
  () => [
    { no: 1, name: "movement_density_heatmap", kind: "message", T: HeatmapData },
    { no: 2, name: "movement_density_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "velocity_graph", kind: "message", T: GraphData },
    { no: 4, name: "distance_coverage_graph", kind: "message", T: GraphData },
    { no: 5, name: "shot_distribution", kind: "message", T: DistributionData },
    { no: 6, name: "hit_common_movement_pattern", kind: "message", T: GridPatternData },
    { no: 7, name: "performance_graph", kind: "message", T: GraphData },
    { no: 8, name: "hit_trajectories", kind: "message", T: HitTrajectory, repeated: true },
    { no: 9, name: "shot_patterns_by_type", kind: "message", T: ShotPatternByType, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.PracticeInfo
 */
export const PracticeInfo = proto3.makeMessageType(
  "recording.v1.PracticeInfo",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fps", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "frames", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "frames_processed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "frame_height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "frame_width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "video_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "thumbnail_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "state", kind: "enum", T: proto3.getEnumType(RecordingState) },
    { no: 11, name: "file_size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "created_at", kind: "message", T: Timestamp },
    { no: 13, name: "updated_at", kind: "message", T: Timestamp },
    { no: 14, name: "reprocessed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "empty_court_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "original_frames", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "player", kind: "enum", T: proto3.getEnumType(Player) },
    { no: 18, name: "type", kind: "enum", T: proto3.getEnumType(PracticeType) },
    { no: 19, name: "is_live", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message recording.v1.ShadowDrillPracticeSetData
 */
export const ShadowDrillPracticeSetData = proto3.makeMessageType(
  "recording.v1.ShadowDrillPracticeSetData",
  () => [
    { no: 1, name: "distance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "velocity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "velocity_avg", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "velocity_max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "performance_index", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "forward_movements", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "backward_movements", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "velocity_graph", kind: "message", T: GraphData },
    { no: 9, name: "distance_coverage_graph", kind: "message", T: GraphData },
    { no: 10, name: "performance_graph", kind: "message", T: GraphData },
  ],
);

/**
 * @generated from message recording.v1.ShadowDrillPracticeSet
 */
export const ShadowDrillPracticeSet = proto3.makeMessageType(
  "recording.v1.ShadowDrillPracticeSet",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "root_start_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "root_end_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "start_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "end_frame", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "data", kind: "message", T: ShadowDrillPracticeSetData },
  ],
);

/**
 * @generated from message recording.v1.ShadowDrillPracticeData
 */
export const ShadowDrillPracticeData = proto3.makeMessageType(
  "recording.v1.ShadowDrillPracticeData",
  () => [
    { no: 1, name: "frames", kind: "message", T: Frame, repeated: true },
    { no: 2, name: "sets", kind: "message", T: ShadowDrillPracticeSet, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.Practice
 */
export const Practice = proto3.makeMessageType(
  "recording.v1.Practice",
  () => [
    { no: 1, name: "info", kind: "message", T: PracticeInfo },
    { no: 2, name: "p1_grids", kind: "message", T: Box, repeated: true },
    { no: 3, name: "p2_grids", kind: "message", T: Box, repeated: true },
    { no: 4, name: "shadow_drill_practice_data", kind: "message", T: ShadowDrillPracticeData, oneof: "data" },
  ],
);

/**
 * @generated from message recording.v1.Annotation
 */
export const Annotation = proto3.makeMessageType(
  "recording.v1.Annotation",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fps", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "frame_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "file_size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "created_at", kind: "message", T: Timestamp },
    { no: 8, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message recording.v1.Export
 */
export const Export = proto3.makeMessageType(
  "recording.v1.Export",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fps", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "frames", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "frames_processed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "state", kind: "enum", T: proto3.getEnumType(ExportState) },
    { no: 7, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "thumbnail_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "file_size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "video_quality", kind: "enum", T: proto3.getEnumType(VideoQuality) },
    { no: 11, name: "created_at", kind: "message", T: Timestamp },
    { no: 12, name: "updated_at", kind: "message", T: Timestamp },
    { no: 13, name: "original_frames", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateAnnotationNameRequest
 */
export const UpdateAnnotationNameRequest = proto3.makeMessageType(
  "recording.v1.UpdateAnnotationNameRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateAnnotationNameResponse
 */
export const UpdateAnnotationNameResponse = proto3.makeMessageType(
  "recording.v1.UpdateAnnotationNameResponse",
  [],
);

/**
 * @generated from message recording.v1.ExportRecordingRequest
 */
export const ExportRecordingRequest = proto3.makeMessageType(
  "recording.v1.ExportRecordingRequest",
  () => [
    { no: 1, name: "recording_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "video_quality", kind: "enum", T: proto3.getEnumType(VideoQuality) },
    { no: 3, name: "show_players", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "show_shuttle", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "show_shuttle_trail_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.ExportRecordingResponse
 */
export const ExportRecordingResponse = proto3.makeMessageType(
  "recording.v1.ExportRecordingResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.AnnotateRecordingRequest
 */
export const AnnotateRecordingRequest = proto3.makeMessageType(
  "recording.v1.AnnotateRecordingRequest",
  () => [
    { no: 1, name: "recording_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "frame_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "file_size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.AnnotateRecordingResponse
 */
export const AnnotateRecordingResponse = proto3.makeMessageType(
  "recording.v1.AnnotateRecordingResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.DeleteExportRequest
 */
export const DeleteExportRequest = proto3.makeMessageType(
  "recording.v1.DeleteExportRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "delete_files", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message recording.v1.DeleteExportResponse
 */
export const DeleteExportResponse = proto3.makeMessageType(
  "recording.v1.DeleteExportResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdateExportNameRequest
 */
export const UpdateExportNameRequest = proto3.makeMessageType(
  "recording.v1.UpdateExportNameRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdateExportNameResponse
 */
export const UpdateExportNameResponse = proto3.makeMessageType(
  "recording.v1.UpdateExportNameResponse",
  [],
);

/**
 * @generated from message recording.v1.ExportRecordingZipRequest
 */
export const ExportRecordingZipRequest = proto3.makeMessageType(
  "recording.v1.ExportRecordingZipRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.ExportRecordingZipResponse
 */
export const ExportRecordingZipResponse = proto3.makeMessageType(
  "recording.v1.ExportRecordingZipResponse",
  () => [
    { no: 2, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.StartLiveRecordingRequest
 */
export const StartLiveRecordingRequest = proto3.makeMessageType(
  "recording.v1.StartLiveRecordingRequest",
  () => [
    { no: 1, name: "camera", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "court", kind: "message", T: Court },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "p1_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "p2_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.StartLiveRecordingResponse
 */
export const StartLiveRecordingResponse = proto3.makeMessageType(
  "recording.v1.StartLiveRecordingResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.CaptureCameraFrameRequest
 */
export const CaptureCameraFrameRequest = proto3.makeMessageType(
  "recording.v1.CaptureCameraFrameRequest",
  () => [
    { no: 1, name: "camera", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.CaptureCameraFrameResponse
 */
export const CaptureCameraFrameResponse = proto3.makeMessageType(
  "recording.v1.CaptureCameraFrameResponse",
  () => [
    { no: 1, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.CreateCameraVerificationFrameRequest
 */
export const CreateCameraVerificationFrameRequest = proto3.makeMessageType(
  "recording.v1.CreateCameraVerificationFrameRequest",
  () => [
    { no: 1, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "court", kind: "message", T: Court },
    { no: 3, name: "camera", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.CreateCameraVerificationFrameResponse
 */
export const CreateCameraVerificationFrameResponse = proto3.makeMessageType(
  "recording.v1.CreateCameraVerificationFrameResponse",
  () => [
    { no: 1, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.Command
 */
export const Command = proto3.makeMessageType(
  "recording.v1.Command",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(CommandType) },
    { no: 2, name: "frame_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "is_player_pos_inverted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message recording.v1.SendLiveRecordingCommandsRequest
 */
export const SendLiveRecordingCommandsRequest = proto3.makeMessageType(
  "recording.v1.SendLiveRecordingCommandsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "command", kind: "message", T: Command },
  ],
);

/**
 * @generated from message recording.v1.SendLiveRecordingCommandsResponse
 */
export const SendLiveRecordingCommandsResponse = proto3.makeMessageType(
  "recording.v1.SendLiveRecordingCommandsResponse",
  () => [
    { no: 1, name: "info", kind: "message", T: RecordingInfo },
  ],
);

/**
 * @generated from message recording.v1.CreatePracticeRequest
 */
export const CreatePracticeRequest = proto3.makeMessageType(
  "recording.v1.CreatePracticeRequest",
  () => [
    { no: 1, name: "video_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.RegeneratePracticeThumbnailRequest
 */
export const RegeneratePracticeThumbnailRequest = proto3.makeMessageType(
  "recording.v1.RegeneratePracticeThumbnailRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.ShadowDrillPracticeProcessSetting
 */
export const ShadowDrillPracticeProcessSetting = proto3.makeMessageType(
  "recording.v1.ShadowDrillPracticeProcessSetting",
  () => [
    { no: 1, name: "velocity_graph_interval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "distance_coverage_graph_interval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "performance_graph_interval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message recording.v1.ProcessPracticeRequest
 */
export const ProcessPracticeRequest = proto3.makeMessageType(
  "recording.v1.ProcessPracticeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "court", kind: "message", T: Court },
    { no: 3, name: "player", kind: "enum", T: proto3.getEnumType(Player) },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(PracticeType) },
    { no: 5, name: "shadow_drill_practice_setting", kind: "message", T: ShadowDrillPracticeProcessSetting, oneof: "setting" },
  ],
);

/**
 * @generated from message recording.v1.ReProcessPracticeRequest
 */
export const ReProcessPracticeRequest = proto3.makeMessageType(
  "recording.v1.ReProcessPracticeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.CreateCourtVerificationFrameForPracticeRequest
 */
export const CreateCourtVerificationFrameForPracticeRequest = proto3.makeMessageType(
  "recording.v1.CreateCourtVerificationFrameForPracticeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "court", kind: "message", T: Court },
  ],
);

/**
 * @generated from message recording.v1.ListPracticesRequest
 */
export const ListPracticesRequest = proto3.makeMessageType(
  "recording.v1.ListPracticesRequest",
  () => [
    { no: 1, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.GetPracticeRequest
 */
export const GetPracticeRequest = proto3.makeMessageType(
  "recording.v1.GetPracticeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sort_direction_annotations", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 3, name: "sort_direction_exports", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ],
);

/**
 * @generated from message recording.v1.DeletePracticeRequest
 */
export const DeletePracticeRequest = proto3.makeMessageType(
  "recording.v1.DeletePracticeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "delete_files", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message recording.v1.CreatePracticeResponse
 */
export const CreatePracticeResponse = proto3.makeMessageType(
  "recording.v1.CreatePracticeResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.RegeneratePracticeThumbnailResponse
 */
export const RegeneratePracticeThumbnailResponse = proto3.makeMessageType(
  "recording.v1.RegeneratePracticeThumbnailResponse",
  [],
);

/**
 * @generated from message recording.v1.ProcessPracticeResponse
 */
export const ProcessPracticeResponse = proto3.makeMessageType(
  "recording.v1.ProcessPracticeResponse",
  [],
);

/**
 * @generated from message recording.v1.ReProcessPracticeResponse
 */
export const ReProcessPracticeResponse = proto3.makeMessageType(
  "recording.v1.ReProcessPracticeResponse",
  [],
);

/**
 * @generated from message recording.v1.CreateCourtVerificationFrameForPracticeResponse
 */
export const CreateCourtVerificationFrameForPracticeResponse = proto3.makeMessageType(
  "recording.v1.CreateCourtVerificationFrameForPracticeResponse",
  () => [
    { no: 1, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.ListPracticesResponse
 */
export const ListPracticesResponse = proto3.makeMessageType(
  "recording.v1.ListPracticesResponse",
  () => [
    { no: 1, name: "practices", kind: "message", T: PracticeInfo, repeated: true },
  ],
);

/**
 * @generated from message recording.v1.GetPracticeResponse
 */
export const GetPracticeResponse = proto3.makeMessageType(
  "recording.v1.GetPracticeResponse",
  () => [
    { no: 1, name: "practice", kind: "message", T: Practice },
  ],
);

/**
 * @generated from message recording.v1.DeletePracticeResponse
 */
export const DeletePracticeResponse = proto3.makeMessageType(
  "recording.v1.DeletePracticeResponse",
  [],
);

/**
 * @generated from message recording.v1.UpdatePracticeEmptyCourtRequest
 */
export const UpdatePracticeEmptyCourtRequest = proto3.makeMessageType(
  "recording.v1.UpdatePracticeEmptyCourtRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message recording.v1.UpdatePracticeEmptyCourtResponse
 */
export const UpdatePracticeEmptyCourtResponse = proto3.makeMessageType(
  "recording.v1.UpdatePracticeEmptyCourtResponse",
  [],
);

