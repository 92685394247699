import { createPromiseClient } from "@bufbuild/connect";
import { createConnectTransport } from "@bufbuild/connect-web";
import { AuthAPI } from "proto/auth/v1/auth_connect";
import { RecordingAPI } from "proto/recording/v1/recording_api_connect";
import { SettingAPI } from "proto/setting/v1/setting_api_connect";
import { UserAPI } from "proto/user/v1/user_api_connect";
import { apiPath } from "server-props";

export const transport = createConnectTransport({
  baseUrl: apiPath,
  credentials: "include",
  interceptors: [
    (next) => async (request) => {
      // Add your headers here
      try {
        const resp = await next(request);
        console.log({ resp });
        return resp;
      } catch (error) {
        console.log("transaport error", { error });
        throw error;
      }
    },
  ],
});

export const transportWithoutInterceptors = createConnectTransport({
  baseUrl: apiPath,
  credentials: "include",
});

export const recordingAPIClient = createPromiseClient(RecordingAPI, transport);
export const settingAPIClient = createPromiseClient(SettingAPI, transport);
export const userAPIClient = createPromiseClient(UserAPI, transport);
export const authAPIClient = createPromiseClient(
  AuthAPI,
  transportWithoutInterceptors
);
